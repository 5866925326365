import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../components/hero";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Unternehmen = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "hero-company.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      andreas: file(relativePath: { eq: "profiles/andreas-boegemann.jpg" }) {
        ...avatarTraced
      }
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        ...avatarTraced
      }
      marcel: file(relativePath: { eq: "profiles/marcel-scheland.jpg" }) {
        ...avatarTraced
      }
      volker: file(relativePath: { eq: "profiles/volker-schroedter.jpg" }) {
        ...avatarTraced
      }
    }

    fragment avatarTraced on File {
      childImageSharp {
        gatsbyImageData(width: 810, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  `);

  return (
    <Layout lang="de" langPath="nav.company.link">
      <Seo
        title="Vom Hamburger Hafen in die digitale Welt"
        description="Das Vertrauen unserer Kunden haben wir uns im Team über mehrere Jahre erarbeitet. So wurde aus einer Gründeridee eine erfolgreiche Agentur."
        keywords="Unternehmen, über uns, Team, Partner, Geschäftsführung, Historie, CMS, Consulting"
      />
      <div className="parallax-translate bg-shape-cross"></div>
      <Hero
        headline="Über uns"
        subline="Das Vertrauen unserer Kunden haben wir uns im Team über mehrere Jahre erarbeitet. So wurde aus einer Gründeridee eine erfolgreiche Agentur."
        section="Unternehmen"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Unternehmen"
        />
      </Hero>
      <div className="container mb-4 mb-md-6">
        <div className="row mb-4">
          <div className="col-12 col-lg-8 offset-lg-4">
            <h3 className="h4">Von Montag zu Monday</h3>
            <p>
              Der Gründungstag von Monday Consulting war der 6. April 2009.
              Natürlich ein Montag. Mit der Vision eines exzellenten
              CMS-Dienstleisters als Leuchtturm und Spaß an der Arbeit im Herzen
              machten wir uns damals auf den Weg vom Hamburger Hafen in die
              digitale Welt. Von Anfang an begeisterte unsere Kunden der Mix aus
              fachlicher Kompetenz und familiärer Zusammenarbeit. So wurden aus
              ersten Projekten langjährige Partnerschaften. Diverse Großprojekte
              gaben uns die Möglichkeit, unser Wissen zu vertiefen und uns
              schließlich als Experten im Bereich Versicherung und Gesundheit zu
              etablieren.
            </p>
            <Link
              to="/referenzen"
              className="btn text-color-white bg-monday-skyblue"
            >
              Mehr zu unseren Referenzen
            </Link>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 col-lg-8 offset-lg-4">
            <h3 className="h4">Vom Hamburger Hafen...</h3>
            <p>
              Unsere hanseatische Herkunft lässt sich schon an der Lage des
              Büros erkennen: An der Grenze zu St. Pauli, oberhalb des
              Fischmarkts und mit Blick auf die Elbe, hat Monday seinen Sitz im
              Herzen von Hamburg. Selbst der Grundriss des Gebäudes ähnelt dem
              eines Schiffrumpfs. Außerdem gibt es in unserem Office keine
              Konferenzräume, sondern Docks.
            </p>
            <p>
              Aber auch unsere Arbeitsweise ist hanseatisch. Monday ist ein
              Unternehmen, in dem die traditionellen kaufmännischen Werte
              großgeschrieben werden. Dazu zählen für uns vor allem
              Zuverlässigkeit, Qualität und Partnerschaft. Auf unser Wort können
              sich Kunden immer verlassen, denn wir wollen gemeinsam mit ihnen
              an hochwertigen Lösungen arbeiten, die für langfristigen Erfolg
              sorgen. Zu ihrer vollsten Zufriedenheit eben.
            </p>
            <Link
              to="/kultur"
              className="btn text-color-white bg-monday-skyblue"
            >
              Mehr zu unserer Kultur
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-4">
            <h3 className="h4">...in die digitale Welt.</h3>
            <p>
              Monday entwirft medienübergreifende Content-Management-Systeme und
              optimiert so den Besucher- und Kundendialog. Wir sind
              spezialisiert auf die Erstellung moderner Software-Lösungen und
              überzeugen durch CMS-Fachkompetenz, professionelle Planung sowie
              durchschlagendes Projektmanagement. Ob Kunden- oder
              Mitarbeiterportal – Content-Management-Lösungen von Monday
              transportieren Informationen, fördern den Dialog, vernetzen
              Menschen und Prozesse und generieren wertvolle Erkenntnisse über
              das Verhalten und die Bedürfnisse von Zielgruppen.
            </p>
            <Link
              to="/leistungen"
              className="btn text-color-white bg-monday-skyblue"
            >
              Mehr zu unseren Leistungen
            </Link>
          </div>
        </div>
      </div>
      <div className="content-container bg-monday-green bg-pattern-vertigo parallax-background">
        <div className="container mb-2 py-5">
          <div className="row text-center text-color-white">
            <div className="col-12 d-flex flex-column align-items-center reveal">
              <h2>Geschäftsführung</h2>
              <p>
                Das Team von Monday setzt sich aus Spezialisten mit
                unterschiedlichsten Erfahrungsstufen zusammen. Und alle bringen
                sich mit ihrem Know-how in Entwicklung, Kreation, Integration,
                Consulting und Projektmanagement ein. Andreas Bögemann, Volker
                Schrödter, Dirk Schrödter und Marcel Scheland bilden dabei als
                „Team im Team“ die Geschäftsführung.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container vertical-offset text-center mb-4 mb-md-6">
        <div className="row">
          <div className="col-12 col-md-6 mb-3 reveal">
            <div className="p-3 h-100 bg-monday-yellow">
              <div className="w-50 mx-auto mb-3">
                <div className="avatar">
                  <div className="img">
                    <GatsbyImage
                      image={images.andreas.childImageSharp.gatsbyImageData}
                      alt="Andreas Bögemann"
                    />
                  </div>
                </div>
              </div>
              <h3 className="h4">Andreas Bögemann</h3>
              <p>
                Als leitender HR-Manager sorgt er für die personelle Entwicklung
                des Monday Teams. Seine Erfahrung als Projektmanager setzt er
                vor allem für unser Produkt Formcentric ein.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-3 reveal">
            <div className="p-3 h-100 bg-monday-yellow">
              <div className="w-50 mx-auto mb-3">
                <div className="avatar">
                  <div className="img">
                    <GatsbyImage
                      image={images.dirk.childImageSharp.gatsbyImageData}
                      alt="Dirk Schrödter"
                    />
                  </div>
                </div>
              </div>
              <h3 className="h4">Dirk Schrödter</h3>
              <p>
                Er verantwortet Vertrieb und Partnermanagement. Als Senior
                Consultant und Software-Architekt für CoreMedia und FirstSpirit
                ist er auch für unser Produkt Formcentric zuständig.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-3 reveal">
            <div className="p-3 h-100 bg-monday-yellow">
              <div className="w-50 mx-auto mb-3">
                <div className="avatar">
                  <div className="img">
                    <GatsbyImage
                      image={images.volker.childImageSharp.gatsbyImageData}
                      alt="Volker Schrödter"
                    />
                  </div>
                </div>
              </div>
              <h3 className="h4">Volker Schrödter</h3>
              <p>
                Ohne sichereres Finanzfundament und eine stabile technische
                Infrastruktur können wir nicht wachsen. Unser erfahrener Senior
                Consultant und Software-Architekt kümmert sich darum.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-3 reveal">
            <div className="p-3 h-100 bg-monday-yellow">
              <div className="w-50 mx-auto mb-3">
                <div className="avatar">
                  <div className="img">
                    <GatsbyImage
                      image={images.marcel.childImageSharp.gatsbyImageData}
                      alt="Marcel Scheland"
                    />
                  </div>
                </div>
              </div>
              <h3 className="h4">Marcel Scheland</h3>
              <p>
                Als Certified Scrum Master und Developer gehört die agile
                Arbeitsweise für den CMS-Experten mit langjähriger
                CoreMedia-Erfahrung zur Tagesordnung. Im Unternehmen
                verantwortet er neben dem Projektgeschäft auch den Bereich
                Corporate Communications & Marketing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Unternehmen;
